import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const EmergencyTowing = () => {
    return (
        <Box>
            <Stack spacing={5} py={5}>
                <Heading as="h2" size="lg">Emergency Towing Services in Los Angeles, CA</Heading>
                <Text fontSize="md">
                    In the fast-paced environment of Los Angeles, encountering a vehicle emergency can be stressful. Los
                    Angeles Towing Services is here to alleviate that stress with our responsive emergency towing
                    services. Whether you're stranded due to a breakdown, involved in an accident, or facing any urgent
                    towing need, our team is prepared to provide rapid, professional assistance.
                </Text>
                <Text fontSize="md">
                    We understand the critical nature of these situations and prioritize quick and effective solutions
                    to ensure your safety and the security of your vehicle.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <Text><b>24/7 Emergency Towing:</b> Our services are available day and night, ensuring that you
                            have access to reliable towing whenever you need it.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>Rapid Dispatch and Response:</b> Our team is always on standby, ensuring swift action
                            to reach you quickly and efficiently in Los Angeles.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>Skilled and Equipped Professionals:</b> Our tow truck operators are experienced and
                            equipped with advanced tools for handling various emergency towing situations.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>Safe Vehicle Handling:</b> We use modern towing equipment and techniques to ensure the
                            safety of your vehicle during transportation.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>Diverse Emergency Services:</b> Apart from towing, we offer a wide array of emergency
                            services, including roadside assistance, vehicle recovery, flatbed towing, and car lockout
                            solutions.</Text>
                    </ListItem>
                </UnorderedList>
                <Text fontSize="md">
                    In any emergency, trust Los Angeles Towing Services for reliable, efficient, and caring towing
                    support.
                </Text>
                <Heading as="h3" size="md">Comprehensive Emergency Towing in LA</Heading>
                <Text fontSize="md">
                    Our emergency towing services are designed to cater to a variety of situations. No matter the
                    challenge, whether it's a simple roadside breakdown or a more complex accident scenario, our team is
                    ready with the right solution.
                </Text>
                <Heading as="h3" size="md">Contact Us for Prompt Emergency Towing</Heading>
                <Text fontSize="md">
                    If you're in need of emergency towing in Los Angeles, don’t hesitate to contact Los Angeles Towing
                    Services. We're here to offer fast, reliable, and empathetic service during your time of need.
                </Text>
                <Text fontSize="md">
                    <Link color="teal.500" href="tel:3239224172">
                        Call us at 323-922-4172 for immediate assistance.
                    </Link>
                </Text>
                <Text fontSize="md">
                    Rely on Los Angeles Towing Services for all your emergency towing needs, and experience the
                    assurance of our dedicated and expert service.
                </Text>
            </Stack>
        </Box>
    )
}