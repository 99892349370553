import {SiteData} from "../Constants/siteData";
import towing1 from '../assets/images/towing1.jpg'
import towing7 from '../assets/images/towing7.jpg'
import towing8 from '../assets/images/towing8.jpg'
import towing9 from '../assets/images/towing9.jpg'
import towing6 from '../assets/images/towing6.jpg'

export const getMetaTitle = (path, title) => {
    const pageName = path ? convertToCamelCase(path) : undefined;
    if (pageName && SiteData[pageName]?.metaData?.title) {
        return SiteData[pageName]?.metaData?.title
    }
    if (title) {
        return title
    }
    if (path?.includes('blog')) {
        return SiteData.blog.metaData.title
    }
    if (path?.includes('contact')) {
        return SiteData.contact.metaData.title
    }
    if (path?.includes('services')) {
        return SiteData.services.metaData.title
    }
    return SiteData.homepage.metaData.title
}

function convertToCamelCase(str) {
    str = str.startsWith('/') ? str.slice(1) : str;
    return str.split('-').map((word, index) => index === 0 ? word : word[0].toUpperCase() + word.slice(1)).join('');
}

export const getMetaDescription = (path, description) => {
    const pageName = path ? convertToCamelCase(path) : undefined;
    if (pageName && SiteData[pageName]?.metaData?.description) {
        return SiteData[pageName]?.metaData?.description
    }
    if (description) {
        return description
    }
    if (path?.includes('blog')) {
        return SiteData.blog.metaData.description
    }
    if (path?.includes('contact')) {
        return SiteData.contact.metaData.description
    }
    if (path?.includes('services')) {
        return SiteData.services.metaData.description
    }
    return SiteData.homepage.metaData.description
}


export const getTowingImage = (id) => {
    switch (id) {
        case '0':
            return towing7
        case '1':
            return towing8
        case '2':
            return towing9
        case '3':
            return towing1
        case '4':
            return towing6
        default:
            break;
    }
}