import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const VehicleRecovery = () => {
    return (
        <Box>
            <Stack spacing={5} py={5}>
                <Heading as="h2" size="lg">Expert Vehicle Recovery Services in Los Angeles, CA</Heading>
                <Text fontSize="md">
                    Los Angeles Towing Services specializes in professional vehicle recovery, providing prompt and safe solutions for vehicles in challenging situations. Whether you're dealing with an accident, off-road incident, or your vehicle is stuck in a difficult spot, we have the expertise and equipment to recover it safely.
                </Text>
                <Text fontSize="md">
                    Our vehicle recovery service is tailored to handle a wide range of scenarios, ensuring your vehicle is retrieved without any further damage.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <Text><b>Advanced Recovery Techniques:</b> We use the latest recovery techniques and equipment to ensure the safe retrieval of your vehicle.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>Skilled Recovery Team:</b> Our experienced professionals are trained to handle complex recovery tasks efficiently and safely.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>24/7 Availability:</b> Vehicle recovery needs can arise at any time. Our services are available around the clock to assist you in emergencies.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>Comprehensive Solutions:</b> In addition to recovery, we offer <Link href={"/roadside-assistance"}>roadside assistance</Link> and <Link href={"/emergency-towing"}>emergency towing</Link> services to cover all your post-recovery needs.</Text>
                    </ListItem>
                </UnorderedList>
                <Text fontSize="md">
                    At Los Angeles Towing Services, we understand the stress of vehicle recovery situations. Our goal is to provide a seamless and worry-free experience.
                </Text>
                <Heading as="h3" size="md">Reliable Vehicle Recovery in Los Angeles</Heading>
                <Text fontSize="md">
                    For dependable and expert vehicle recovery services in Los Angeles, trust our team to deliver the support you need.
                </Text>
                <Text fontSize="md">
                    <Link color="teal.500" href="tel:3239224172">
                        Call us at 323-922-4172 for efficient vehicle recovery assistance.
                    </Link>
                </Text>
                <Text fontSize="md">
                    Choose Los Angeles Towing Services for all your vehicle recovery needs, where safety and professionalism are always our top priorities.
                </Text>
            </Stack>
        </Box>
    )
}