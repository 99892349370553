import React from "react";
import towingMeta from '../assets/images/meridenmeta.jpg'
import bgImage from '../assets/images/towing10.jpg'
import {posts} from "./posts";
import {Box, Button, Heading, Link, List, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {GiCarBattery, GiFlatTire, GiFuelTank, GiPathDistance, GiTowTruck} from "react-icons/gi";
import {BsTruckFlatbed} from "react-icons/bs";
import {BiLockOpenAlt} from "react-icons/bi";
import {MdEmergencyShare} from "react-icons/md";
import {TbRoad} from "react-icons/tb";
import {PiClockCountdownFill} from "react-icons/pi";
import {RiEmotionHappyLine} from "react-icons/ri";
import {CarLockoutService} from "../Components/CarLockoutService/CarLockoutService";
import {VehicleRecovery} from "../Components/VehicleRecovery/VehicleRecovery";
import {LongDistanceTowing} from "../Components/LongDistanceTowing/LongDistanceTowing";
import {FlatbedTowing} from "../Components/FlatbedTowing/FlatbedTowing";
import {RoadsideAssistance} from "../Components/RoadsideAssistance/RoadsideAssistance";
import {EmergencyTowing} from "../Components/EmergencyTowing/EmergencyTowing";
import {FlatTire} from "../Components/FlatTire/FlatTire";
import {JumpStart} from "../Components/JumpStartService/JumpStart";
import {FuelDelivery} from "../Components/FuelDelivery/FuelDelivery";

export const SiteData = {
    hoursOfOperation: "24/7",
    city: 'Los Angeles, CA',
    phoneNumber: '(323) 922-4172',
    telLink: 'tel:3239224172',
    title: 'Towing Service in Los Angeles, CA | 24-Hour Towing | 323-922-4172',
    buttonCtaText: 'Call Now for 24/7 Emergency Towing Assistance in LA!',
    keywords: 'Towing, roadside assistance, emergency, flatbed, vehicle recovery, Los Angeles, CA, professional, reliable.',
    footerText: 'Los Angeles Towing Services, All rights reserved.',
    ogImage: towingMeta,
    navLinks: [
        {
            title: 'Home',
            href: '/'
        },
        {
            title: 'Services',
            href: '/services'
        },
        {
            title: 'Blog',
            href: '/blog'
        },
        {
            title: 'About',
            href: '/about'
        },
        {
            title: 'Contact Us',
            href: '/contact'
        },
        {
            title: 'Careers',
            href: '/careers',
            hideFromTopNav: true
        },
    ],
    signupForm: {
        // intro: () => <Text>We are proud to be Meriden's top choice for towing services. We provide a variety of towing
        //     services for all size vehicles big and small. Count on Meriden Towing Service to provide reliable,
        //     trustworthy vehicle towing to our local community and the surrounding areas. We offer on-the-spot assistance
        //     24/7 to get your car, truck, or other vehicle to safety. We're just a <Link href={'tel:4752428150'}
        //                                                                                 textDecoration={'underline'}>phone
        //         call away.</Link></Text>,
        // servicesTitle: 'Our Services:',
    },
    homepage: {
        url: 'https://www.losangelestowingservices.com',
        bgImage: bgImage,
        metaData: {
            title: 'Los Angeles Towing Services: Reliable 24/7 Roadside Assistance in Los Angeles | Call 323-922-4172',
            description: 'Los Angeles Towing Services provides dependable 24/7 roadside assistance and towing solutions in Los Angeles. Contact us for prompt, professional towing services anytime you need.',
        },
        h1: 'Round-the-Clock Towing in Los Angeles, California',
        subtitle: 'Your Go-To Roadside Assistance and Towing Partner in Los Angeles, CA',
        servicesTitle: 'Why Los Angeles Drivers Trust Our Towing Services',
        servicesHeader: 'Comprehensive Towing Solutions for Every Roadside Challenge',
        titleHead: 'LOS ANGELES TOWING BY THE NUMBERS',
        sectionTitle: 'Every Call, Every Tow – Demonstrating Our Dedication to Los Angeles Drivers',
        content: [
            <>
                <Heading as="h2" size={'md'} pb='2'>Hello, LA! Meet Your Towing Saviors at Los Angeles Towing
                    Services</Heading>
                <Text>
                    Hey there, Los Angeles! We're the dynamic crew at Los Angeles Towing Services, your go-to problem
                    solvers for all things vehicular. Right in the pulsating heart of LA, we're the team you think of
                    when your car throws a curveball. Breakdown? Lockout? Need a lift? We're your guys - quick,
                    dependable, and easy on your pocket. Think of us as your roadside superheroes, ready to save the
                    day.
                </Text>
                <Text>
                    Towing for us is more than just transporting; it's about providing a silver lining when your day
                    gets cloudy. Got a flat? Need to shift your vehicle? We're on it with our speedy, secure, and
                    knowledgeable service, geared to flip your day right-side up.
                </Text>
                <Heading as="h2" size={'md'} pb='2'>24/7 Rescue Missions with Los Angeles Towing Services</Heading>
                <Text>
                    Car trouble is no respecter of time, and neither are we. Our Los Angeles Towing Services team is up
                    and running 24/7. It doesn't matter if it's the crack of dawn or the dead of night; a quick call
                    to <Link href={'tel:3239224172'}>(323) 922-4172</Link> brings our team to your rescue. Fast,
                    reliable, and always there - that's our promise.
                </Text>
                <Heading as="h2" size={'md'} pb='2'>Got a Car Crisis in LA? Ring Los Angeles Towing
                    Services!</Heading>
                <Text>
                    Stuck somewhere in LA? Keep calm and call Los Angeles Towing Services. Just dial <Link
                    href={'tel:3239224172'}>(323) 922-4172</Link>, and help is on its way. We'll greet you with a free
                    quote and a friendly voice. And don't sweat the payment stuff – we're all about flexibility here. No
                    matter the car crisis, we've got your back.
                </Text>
                <Heading as="h2" size={'md'} pb='2'>Los Angeles Towing Services: Custom Fit for Your Needs</Heading>
                <Text>
                    Check out what we have on offer at Los Angeles Towing Services:
                </Text>
                <Text>
                    <UnorderedList>
                        <ListItem><Link href={"/emergency-towing"}>Emergency Towing</Link>: On-call for your urgent
                            situations, 24/7.</ListItem>
                        <ListItem><Link href={"/roadside-assistance"}>Roadside Assistance</Link>: Solving everything
                            from minor hiccups to major setbacks.</ListItem>
                        <ListItem><Link href={"/vehicle-recovery"}>Vehicle Recovery</Link>: Smooth, caring service for
                            post-accident scenarios.</ListItem>
                        <ListItem><Link href={"/long-distance-towing"}>Long Distance Towing</Link>: Reliable, long-haul
                            transport for your prized vehicle.</ListItem>
                        <ListItem><Link href={"/flatbed-towing"}>Flatbed Towing</Link>: Special care for your special
                            rides.</ListItem>
                        <ListItem><Link href={"/car-lockout-service"}>Car Lockout Services</Link>: Locked out? We'll get
                            you back in, no sweat.</ListItem>
                        <ListItem><Link href={"/flat-tire-services"}>Flat Tire Services</Link>: Tire troubles? We'll fix
                            them in a jiffy.</ListItem>
                        <ListItem><Link href={"/fuel-delivery"}>Fuel Delivery Services</Link>: Out of gas? We'll fill
                            you up and get you going.</ListItem>
                        <ListItem><Link href={"/jump-start-service"}>Jump Start Services</Link>: Dead battery? We'll
                            bring it back to life.</ListItem>
                    </UnorderedList>
                </Text>
                <Text>
                    Tailored to meet the unique demands of LA drivers - that's our service in a nutshell.
                </Text>
                <Heading as="h2" size={'md'} pb='2'>Top-Notch Towing Without the Price Shock</Heading>
                <Text>
                    Good towing shouldn't cost a fortune, and at Los Angeles Towing Services, it doesn't. We stand for
                    transparent, honest pricing – quality service without the wallet worries.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Why LA Picks Los Angeles Towing Services</Heading>
                <Text>
                    There are tons of reasons to choose us, but here are just a few:
                </Text>
                <Text>
                    <UnorderedList>
                        <ListItem>Rapid and thorough – because we know your time is gold.</ListItem>
                        <ListItem>Solutions for every roadside drama – your one-stop roadside rescue shop.</ListItem>
                        <ListItem>We know LA like the back of our hand – navigating this city is our game.</ListItem>
                        <ListItem>Prices that keep you smiling – excellent service that doesn't drain your
                            pocket.</ListItem>
                        <ListItem>Always on call – we're here for you all day, every day.</ListItem>
                    </UnorderedList>
                </Text>
                <Text>
                    We're more than a towing service; we're part of LA's heartbeat, committed to making our roads safer
                    and our community happier.
                </Text>
                <Heading as="h2" size={'md'} pb='2'>Your 24-Hour Towing Buddy in LA</Heading>
                <Text>
                    When car woes strike out of the blue, don't fret – Los Angeles Towing Services is just a call away.
                    Reach out for quick, effective help.
                </Text>
                <Heading as="h2" size={'md'} pb='2'>Los Angeles Towing Services: Here for Your Every Roadside
                    Challenge</Heading>
                <Text>
                    Need a hand on Los Angeles roads? Dial (323) 922-4172 for Los Angeles Towing Services. We’re ready
                    with a free quote and a smile, showing you why we’re the talk of the town. From cash to cards, we
                    make payments easy. Whenever you're in a pickle, remember – we're just a call away.
                </Text>

                <Button as={'a'} href={'tel:3239224172'} bg={'#17b644'} fontSize={{base: '20px', md: '23px'}}
                        color={'white'} fontWeight={'bold'}
                        _hover={{bg: '#06c03b', color: 'white', cursor: 'pointer'}}
                        p={'16px 30px'} borderRadius={0} mt={'2rem'}
                        lineHeight={'26px'}
                        minH={'66px'}
                        width={'100%'}
                        maxW={'400px'}><i className="fa fa-phone" aria-hidden="true"/>&nbsp;&nbsp;Call for Immediate
                    Assistance</Button>
            </>
        ],
        values: [
            {
                id: 0,
                number: "10,000+",
                name: 'Vehicles Towed',
                icon: GiTowTruck
            },
            {
                id: 1,
                number: "24/7",
                name: 'Service Availability',
                icon: MdEmergencyShare
            },
            {
                id: 2,
                number: "99%",
                name: 'Customer Satisfaction',
                icon: RiEmotionHappyLine
            },
            {
                id: 3,
                number: "30min",
                name: 'Avg Response Time',
                icon: PiClockCountdownFill
            }
        ],
        servicesSubtitle: () => <Text color="muted" fontSize={{base: 'lg', md: 'xl'}}>Discover our wide range of
            services at <Link href={'/services'}>Los Angeles Towing Services</Link> – from prompt towing solutions to
            comprehensive roadside assistance, we’re your all-around support for any road challenges in LA.</Text>,
        featuredServices: [
            {
                name: '24 Hour Emergency Towing',
                description: 'Around-the-clock towing services for any emergency situation in Los Angeles. Whether it’s late at night or early in the morning, we’re here to help you when you need it the most.',
                icon: MdEmergencyShare,
                path: '/emergency-towing'
            },
            {
                name: 'Roadside Assistance',
                description: 'Complete roadside assistance services, including tire changes, jump starts, and fuel delivery. We’re here to get you back on the road quickly and safely.',
                icon: TbRoad,
                path: '/roadside-assistance'
            },
            {
                name: 'Vehicle Recovery',
                description: 'Expert vehicle recovery services for those challenging times following accidents or breakdowns. We handle your vehicle with care and professionalism.',
                icon: GiTowTruck,
                path: '/vehicle-recovery'
            },
            {
                name: 'Long-Distance Towing',
                description: 'Reliable long-distance towing services to transport your vehicle safely across Los Angeles or beyond. Trust us for a secure and hassle-free experience.',
                icon: GiPathDistance,
                path: '/long-distance-towing'
            },
            {
                name: 'Flatbed Towing',
                description: 'Specialized flatbed towing services ideal for transporting luxury, exotic, or damaged vehicles. We provide the utmost care to ensure your vehicle’s safety during transit.',
                icon: BsTruckFlatbed,
                path: '/flatbed-towing'
            },
            {
                name: 'Car Lockout Service',
                description: 'Quick and efficient car lockout services to help you regain access to your vehicle without any damage. We’re here to assist you when you’re locked out.',
                icon: BiLockOpenAlt,
                path: '/car-lockout-service'
            },
            {
                name: 'Flat Tire Services',
                description: 'Prompt flat tire services including tire changes and repairs. We ensure your vehicle is ready to hit the road safely and swiftly.',
                icon: GiFlatTire,
                path: '/flat-tire-services'
            },
            {
                name: 'Fuel Delivery Services',
                description: 'Stranded without gas in Los Angeles? Los Angeles Towing Services offers rapid and efficient fuel delivery. Just call us at 323-922-4172, and we\'ll ensure you\'re back on the road in no time.',
                icon: GiFuelTank,
                path: '/fuel-delivery'
            },
            {
                name: 'Jump Start Services',
                description: 'Dealing with a dead battery? Los Angeles Towing Services provides professional jump start services in Los Angeles. Quick, reliable, and safe battery boosting is just a call away at 323-922-4172.',
                icon: GiCarBattery,
                path: '/jump-start-service'
            }
        ],
        services: [
            {
                id: '0',
                title: 'Rapid Response Time',
                text: () => <Text>Count on us for immediate assistance with our fast response times, ensuring you’re
                    never left stranded on the roads of Los Angeles.</Text>
            },
            {
                id: '1',
                title: 'Skilled and Professional Team',
                text: () => <Text>Our team of certified and experienced professionals is equipped to handle all your
                    towing and roadside needs with the utmost care and expertise.</Text>
            },
            {
                id: '2',
                title: 'Competitive Pricing',
                text: () => <Text>We believe in providing top-quality towing services at competitive prices, offering
                    transparent and fair rates to all our Los Angeles customers.</Text>
            },
            {
                id: '3',
                title: '24/7 Availability',
                text: () => <Text>Our services are available around the clock, ensuring you have access to reliable
                    towing and roadside assistance any time of day in Los Angeles.</Text>
            },
            {
                id: '4',
                title: 'Trust and Reliability',
                text: () => <Text>As a trusted local towing company in Los Angeles, we’re committed to delivering
                    reliable and trustworthy services, prioritizing your safety and satisfaction.</Text>
            }
        ],
        footerHeading: 'Get in Touch with Los Angeles Towing Services',
        footerText: () => <>For dependable roadside assistance in Los Angeles and surrounding areas, contact us 24/7.
            Our team is ready to provide timely support in any emergency situation. <Link href={'tel:3239224172'}>Call
                us</Link> or visit our <Link href={'/contact'}>contact page</Link> for non-urgent inquiries. Your safety
            and peace of mind are our top priority at Los Angeles Towing Services.</>,
        footerText2: 'Dedicated to Ensuring Your Safety and Satisfaction',
    },
    careers: {
        url: "https://www.losangelestowingservices.com/careers/",
        metaData: {
            title: "Join Our Team - Career Opportunities at Los Angeles Towing Services",
            description: "Explore career opportunities at Los Angeles Towing Services. Join our team of dedicated professionals in providing top-tier towing and roadside assistance in Los Angeles."
        },
        h1: "We're Hiring - Join Los Angeles Towing Services",
        h2: [
            "Why Choose Los Angeles Towing Services as Your Workplace?",
            "Current Job Openings in Towing and Roadside Assistance",
            "Our Commitment to Employee Development and Satisfaction"
        ],
        headingText: {
            whyJoin: "Join a dynamic team committed to excellence in towing and roadside assistance in the vibrant city of Los Angeles.",
            openings: "Check out our latest job openings for roles that match your skills and ambition in the towing industry.",
            commitment: "At Los Angeles Towing Services, we value our employees and offer an environment conducive to professional growth and job satisfaction."
        },
        content: [
            <Text>Welcome to the Careers page at <Link href={'/'}>Los Angeles Towing Services</Link>. We're a tight-knit
                team dedicated to outstanding performance in towing and roadside assistance. Be part of a team that
                makes a real difference in Los Angeles.</Text>,
            <Text>At <Link href={'/'}>Los Angeles Towing Services</Link>, we know our strength lies in our dedicated
                team. We're seeking professionals passionate about delivering top-quality towing services. Whether
                you’re experienced or new to the field, we offer a dynamic workplace where your talents are nurtured and
                valued.</Text>,
            <Text>Our team members enjoy a supportive atmosphere that fosters personal and professional growth. Benefits
                include comprehensive training, competitive salaries, and access to the latest equipment. We aim to
                create a fulfilling and enjoyable work environment for everyone.</Text>,
            <Text>As a key player in Los Angeles's community, we take pride in offering services marked by integrity and
                professionalism. Our commitment to excellence extends to our team members, whom we support through a
                positive workplace culture, teamwork, and advancement opportunities.</Text>,
            <Text>Interested in joining a leading towing service provider in Los Angeles, CA? Explore our current job
                openings and find your fit in our expanding team. Opportunities range from tow truck operation to
                customer service and administrative roles.</Text>,
            <Text><Link href={'https://www.losangelestowingservices.com/careers/'}>Find your next career
                opportunity</Link> with us. Join a team reshaping the towing industry in Los Angeles. At Los Angeles
                Towing Services, we’re more than a company; we’re a community. Apply today and steer your career
                forward.</Text>
        ]
    },
    about: {
        url: "https://www.losangelestowingservices.com/about/",
        metaData: {
            title: "About Los Angeles Towing Services - Your Trusted Towing Partner in LA",
            description: "Discover Los Angeles Towing Services, your reliable partner for all towing and roadside assistance needs in Los Angeles, CA. Available 24/7 for any emergency."
        },
        h1: "About Los Angeles Towing Services",
        h2: [
            "Our Dedication to Quality Towing and Roadside Assistance",
            "Meet Our Skilled Towing Team",
            "A Wide Range of Towing and Recovery Services"
        ],
        headingText: {
            commitment: "Committed to providing exceptional towing and roadside support to the Los Angeles community.",
            team: "Our experienced and trained professionals are always ready to respond, ensuring top-quality service in every situation.",
            services: "Explore our wide array of towing solutions, catering to all types of vehicle emergencies and transport needs in Los Angeles."
        },
        services: [
            {
                id: "0",
                title: '24 Hour Emergency Towing',
                href: '/emergency-towing',
                icon: MdEmergencyShare,
                description: <Text>Rapid, reliable 24-hour towing services, ready to respond to emergencies any time in
                    Los Angeles.</Text>
            },
            {
                id: "1",
                title: 'Roadside Assistance',
                href: '/roadside-assistance',
                icon: TbRoad,
                description: <Text>Dependable roadside support for all situations, from jump-starts to tire changes,
                    ensuring you're back on your way swiftly.</Text>
            },
            {
                id: "2",
                title: 'Vehicle Recovery',
                href: '/vehicle-recovery',
                icon: GiTowTruck,
                description: <Text>Specialized in safe vehicle recovery, we handle post-accident scenarios with
                    expertise and care.</Text>
            },
            {
                id: "3",
                title: 'Long-Distance Towing',
                href: '/long-distance-towing',
                icon: GiPathDistance,
                description: <Text>Secure long-distance towing solutions to transport your vehicle to any destination,
                    prioritizing safety and timeliness.</Text>
            },
            {
                id: "4",
                title: 'Flatbed Towing',
                href: '/flatbed-towing',
                icon: BsTruckFlatbed,
                description: <Text>Expert flatbed towing services for high-end and specialty vehicles, ensuring maximum
                    protection during transit.</Text>
            },
            {
                id: "5",
                title: 'Car Lockout Service',
                href: '/car-lockout-service',
                icon: BiLockOpenAlt,
                description: <Text>Efficient car lockout solutions to quickly regain access to your vehicle without any
                    damage.</Text>
            },
            {
                id: "6",
                title: "Flat Tire Service",
                href: "/flat-tire-services",
                icon: GiFlatTire,
                description: <Text>Expert tire changing and repair services, getting you back on the road safely and
                    without delay.</Text>
            }
        ]
    },
    services: {
        url: 'https://www.losangelestowingservices.com/services/',
        metaData: {
            title: 'Expert Towing Services in Los Angeles | Los Angeles Towing Services',
            description: 'Explore our professional towing services in Los Angeles. Offering 24/7 roadside assistance, emergency towing, and more. Contact us for reliable and efficient towing solutions.',
        },
        h1: 'Towing Services in Los Angeles, CA',
        h2: 'Your Dependable Partner for All Towing Needs in LA',
        content: <Box>
            <Stack spacing={5}>
                <Heading as="h2" size="xl">Los Angeles Towing Services: Your Trusted Roadside Ally in LA</Heading>
                <Text>Welcome to Los Angeles Towing Services, where we provide top-notch towing and roadside assistance
                    across Los Angeles. Our team is committed to delivering prompt, safe, and reliable services,
                    catering to a variety of needs from emergency towing to routine vehicle transport. We pride
                    ourselves on excellence and customer satisfaction.</Text>

                <Heading as="h3" size="lg">Why Choose Los Angeles Towing Services</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Skilled Professionals:</b> Our experienced team is equipped to handle diverse
                        towing situations, ensuring your vehicle is in safe and capable hands.</Text></ListItem>
                    <ListItem><Text><b>State-of-the-Art Equipment:</b> Utilizing the latest in towing technology, we
                        provide efficient and safe services for all types of vehicles.</Text></ListItem>
                    <ListItem><Text><b>Round-the-Clock Availability:</b> We understand that emergencies can happen
                        anytime. Our services are available 24/7 for your peace of mind.</Text></ListItem>
                    <ListItem><Text><b>Transparent Pricing:</b> Offering competitive rates with clear pricing, we ensure
                        quality services at affordable prices.</Text></ListItem>
                    <ListItem><Text><b>Expertise in LA:</b> With extensive knowledge of Los Angeles, we ensure quick
                        response times and efficient route navigation for a seamless towing
                        experience.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Our Extensive Towing & Roadside Assistance Services</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Emergency Towing:</b> Available to assist in any emergency, our team provides
                        timely and secure transportation of your vehicle.</Text></ListItem>
                    <ListItem><Text><b>Roadside Assistance:</b> From tire changes to jump starts, we're ready to help
                        you get back on the road with minimal downtime.</Text></ListItem>
                    <ListItem><Text><b>Flat Tire Services:</b> Quick and efficient flat tire assistance to ensure your
                        safety and convenience.</Text></ListItem>
                    <ListItem><Text><b>Vehicle Lockout Services:</b> Fast and damage-free lockout services to help you
                        regain access to your vehicle.</Text></ListItem>
                    <ListItem><Text><b>Long-Distance Towing:</b> Trust us for reliable long-distance towing services,
                        ensuring your vehicle reaches its destination safely.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Tailored Solutions for Personal & Business Needs</Heading>
                <Text>At Los Angeles Towing Services, we understand that every client has unique needs. We provide
                    customized towing and roadside assistance solutions for both individual and commercial clients,
                    ensuring each requirement is met with precision and care.</Text>

                <Heading as="h3" size="lg">Safety and Satisfaction: Our Top Priorities</Heading>
                <Text>Your safety and satisfaction are at the forefront of our services. We are dedicated to delivering
                    high-quality services that exceed your expectations, ensuring a smooth and efficient experience from
                    start to finish.</Text>

                <Heading as="h3" size="lg">Contact Los Angeles Towing Services Today</Heading>
                <Text>For exceptional towing and roadside assistance in Los Angeles, choose Los Angeles Towing Services.
                    Contact us today for expert, timely service. We're here to support you whenever you need us.</Text>
            </Stack>
        </Box>,
        services: [
            {
                id: '0',
                text: () => <Text><b><Link href={'/emergency-towing'}>24 Hour Emergency Towing</Link></b> Our fleet is
                    ready to respond quickly and efficiently, providing emergency towing services throughout Los
                    Angeles, ensuring your safety and convenience during unexpected situations.</Text>
            },
            {
                id: '1',
                text: () => <Text><b><Link href={'/roadside-assistance'}>Roadside Assistance</Link></b> Whether you're
                    dealing with a flat tire, a dead battery, or need fuel delivery, our roadside assistance team is
                    equipped to get you back on your journey with minimal delay.</Text>
            },
            {
                id: '2',
                text: () => <Text><b><Link href={'/vehicle-recovery'}>Vehicle Recovery</Link></b> In case of accidents
                    or off-road incidents, our vehicle recovery services ensure your vehicle is retrieved safely and
                    efficiently, with utmost care and professionalism.</Text>
            },
            {
                id: '3',
                text: () => <Text><b><Link href={'/long-distance-towing'}>Long-Distance Towing</Link></b> We offer
                    dependable long-distance towing services across Los Angeles and beyond, ensuring your vehicle is
                    transported securely to its destination, no matter the distance.</Text>
            },
            {
                id: '4',
                text: () => <Text><b><Link href={'/flatbed-towing'}>Flatbed Towing</Link></b> Our flatbed towing
                    services are perfect for transporting luxury, exotic, or specialty vehicles, providing the highest
                    level of care and protection during transit.</Text>
            },
            {
                id: '5',
                text: () => <Text><b><Link href={'/car-lockout-service'}>Car Lockout Service</Link></b> Locked out of
                    your car? Our expert lockout services offer a quick, efficient solution to regain access to your
                    vehicle without any damage.</Text>
            },
            {
                id: '6',
                text: () => <Text><b><Link href={'/flat-tire-services'}>Flat Tire Services</Link></b> Stranded with a
                    flat tire? Our team offers prompt tire change and repair services to get you back on the road safely
                    and quickly.</Text>
            }
        ]
    },
    contact: {
        url: 'https://www.losangelestowingservices.com/contact/',
        metaData: {
            title: 'Contact Los Angeles Towing Services: 24/7 Reliable Support | Call 323-922-4172',
            description: 'Need towing assistance in Los Angeles? Contact Los Angeles Towing Services for 24/7 reliable support. Our team is ready to provide you with professional and efficient towing services.',
        },
        h1: 'Connect With Los Angeles Towing Services',
        subtitle: () => <>Thank you for considering Los Angeles Towing Services. Our experienced team in Los Angeles is
            committed
            to providing you with swift and reliable service. <Link href={'tel:3239224172'}>Call us</Link> or use
            our <Link href={'/contact'}>online form</Link> for non-emergency queries. We are your trusted partner for
            all your towing and roadside assistance needs.</>,
        sectionHeading: 'Choose Los Angeles Towing Services for Dependable Assistance',
        signUpDescription: 'To reach out to us online, fill out our contact form. We will respond promptly to address your needs and provide the assistance you require.',
        feedbackText: 'Your feedback is invaluable to us! Please share your experiences or inquiries. We\' re dedicated to improving our services and ensuring your utmost satisfaction.',
        closingText: 'Reach out to us for any towing or roadside assistance needs – we\'re here to help you.',
        valueProps: [
            {
                title: '24/7 Availability:',
                description: 'Our team is ready to assist you at any hour, providing reliable towing and roadside assistance day or night. Quick response and efficient service are our guarantees to you.',
            },
            {
                title: 'Comprehensive Services:',
                description: 'Los Angeles Towing Services offers a wide range of services, from emergency towing to specialized transport, ensuring we meet all your towing needs with expertise and care.'
            },
            {
                title: 'State-of-the-Art Equipment:',
                description: 'Our team is equipped with advanced towing tools and vehicles, ensuring safe and efficient handling of your vehicle, regardless of its type or condition.'
            },
            {
                title: 'Professional Team:',
                description: 'Our skilled professionals are experienced and trained to provide high-quality towing services, handling each situation with professionalism and attention to detail.'
            },
            {
                title: 'Commitment to Customer Satisfaction:',
                description: 'At Los Angeles Towing Services, customer satisfaction is our top priority. We strive to provide a seamless experience, ensuring your peace of mind during the towing process.'
            },
        ]
    },
    blog: {
        url: 'https://www.losangelestowingservices.com/blog',
        metaData: {
            title: 'Los Angeles Towing Services Blog - Tips & Insights | Towing and Roadside Assistance',
            description: 'Stay updated with the latest tips and insights from Los Angeles Towing Services. Learn about towing safety, roadside assistance, and more in our informative blog.',
        },
        h1: 'Towing and Roadside Assistance Blog',
        h2: 'Our Latest Articles and Tips',
        heading: 'Get the latest insights and tips on towing and roadside assistance from Los Angeles Towing Services. Whether you need advice on emergency towing or want to learn more about our services, our blog is your go-to resource.',
        posts: posts
    },
    emergencyTowing: {
        url: 'https://www.losangelestowingservices.com/emergency-towing/',
        metaData: {
            title: 'Emergency Towing Services in Los Angeles, CA | Los Angeles Towing Services',
            description: 'Need emergency towing in Los Angeles? Los Angeles Towing Services offers 24/7 emergency towing. Quick, reliable, and professional. Call us for immediate assistance.'
        },
        h1: '24/7 Emergency Towing Services in Los Angeles | Los Angeles Towing Services',
        subtitle: 'Los Angeles Towing Services is always ready to provide fast and reliable emergency towing services in Los Angeles, ensuring help is available whenever you need it most.',
        body: <EmergencyTowing/>
    },
    roadsideAssistance: {
        url: 'https://www.losangelestowingservices.com/roadside-assistance/',
        metaData: {
            title: 'Roadside Assistance in Los Angeles, CA | Los Angeles Towing Services',
            description: 'Los Angeles Towing Services offers prompt and reliable roadside assistance in Los Angeles. From flat tires to battery jumps, we’re here to help 24/7.'
        },
        h1: 'Reliable Roadside Assistance Services in Los Angeles | Los Angeles Towing Services',
        subtitle: 'Rely on Los Angeles Towing Services for comprehensive roadside assistance in Los Angeles, available 24/7 to cater to your roadside emergencies.',
        body: <RoadsideAssistance/>
    },
    flatbedTowing: {
        url: 'https://www.losangelestowingservices.com/flatbed-towing/',
        metaData: {
            title: 'Flatbed Towing Services in Los Angeles, CA | Los Angeles Towing Services',
            description: 'Choose Los Angeles Towing Services for safe and secure flatbed towing in Los Angeles. Ideal for luxury and specialty vehicles. Contact us for efficient service.'
        },
        h1: 'Professional Flatbed Towing Services in Los Angeles | Los Angeles Towing Services',
        subtitle: 'Los Angeles Towing Services specializes in flatbed towing, providing safe and secure transport for all types of vehicles in Los Angeles.',
        body: <FlatbedTowing/>
    },
    carLockoutService: {
        url: 'https://www.losangelestowingservices.com/car-lockout-service/',
        metaData: {
            title: 'Car Lockout Services in Los Angeles, CA | Los Angeles Towing Services',
            description: 'Locked out of your car in Los Angeles? Los Angeles Towing Services provides quick and damage-free car lockout services. Contact us for immediate help.'
        },
        h1: 'Fast and Efficient Car Lockout Services in Los Angeles | Los Angeles Towing Services',
        subtitle: 'Get fast and reliable car lockout services in Los Angeles with Los Angeles Towing Services. We ensure quick access to your vehicle without any damage.',
        body: <CarLockoutService/>
    },
    vehicleRecovery: {
        url: 'https://www.losangelestowingservices.com/vehicle-recovery/',
        metaData: {
            title: 'Vehicle Recovery Services in Los Angeles, CA | Los Angeles Towing Services',
            description: 'Los Angeles Towing Services offers professional vehicle recovery services in Los Angeles. Efficient, safe, and reliable. Call us for quick recovery assistance.'
        },
        h1: 'Expert Vehicle Recovery Services in Los Angeles | Los Angeles Towing Services',
        subtitle: 'Trust Los Angeles Towing Services for efficient and professional vehicle recovery services in Los Angeles, ensuring the safe retrieval of your vehicle.',
        body: <VehicleRecovery/>
    },
    flatTire: {
        url: 'https://www.losangelestowingservices.com/flat-tire-services/',
        metaData: {
            title: "Flat Tire Services in Los Angeles, CA | Los Angeles Towing Services",
            description: "Stranded with a flat tire in Los Angeles? Los Angeles Towing Services offers quick and reliable flat tire services. Call us for fast tire repairs and replacements."
        },
        h1: "Efficient Flat Tire Services in Los Angeles | Los Angeles Towing Services",
        subtitle: "Need help with a flat tire in Los Angeles? Los Angeles Towing Services provides prompt and efficient flat tire solutions, ensuring your safety and convenience.",
        body: <FlatTire/>
    },
    longDistanceTowing: {
        url: 'https://www.losangelestowingservices.com/long-distance-towing/',
        metaData: {
            title: 'Long-Distance Towing Services in Los Angeles, CA | Los Angeles Towing Services',
            description: 'Need long-distance towing in Los Angeles? Los Angeles Towing Services offers reliable long-distance towing services. Safe, secure, and on time. Call us for your towing needs.'
        },
        h1: 'Reliable Long-Distance Towing Services in Los Angeles | Los Angeles Towing Services',
        subtitle: 'For all your long-distance towing needs in Los Angeles, trust Los Angeles Towing Services. We ensure the safe and timely transport of your vehicle.',
        body: <LongDistanceTowing/>
    },
    fuelDelivery: {
        url: 'https://www.losangelestowingservices.com/fuel-delivery/',
        metaData: {
            title: 'Rapid Fuel Delivery in Los Angeles, CA | Los Angeles Towing Services',
            description: 'Stranded without gas in Los Angeles? Call Los Angeles Towing Services at 323-922-4172 for swift and reliable fuel delivery. We\'ll get you back on the road quickly.'
        },
        h1: 'Emergency Fuel Delivery Services in Los Angeles, CA | Los Angeles Towing Services',
        subtitle: 'Count on Los Angeles Towing Services for efficient and dependable fuel delivery, ensuring you never have to worry about an empty tank.',
        body: <FuelDelivery/>
    },
    jumpStartService: {
        url: 'https://www.losangelestowingservices.com/jump-start-service/',
        metaData: {
            title: 'Professional Jump Start Service in Los Angeles, CA | Los Angeles Towing Services',
            description: 'Battery dead? Contact Los Angeles Towing Services at 323-922-4172 for expert jump start services in Los Angeles. We provide swift and safe battery revival solutions.'
        },
        h1: 'Reliable Jump Start Services in Los Angeles, CA | Los Angeles Towing Services',
        subtitle: 'Choose Los Angeles Towing Services for immediate and skilled jump start assistance, guaranteeing a quick recharge of your vehicle.',
        body: <JumpStart/>
    },
    testimonials: {
        h1: 'Hear from Our Satisfied Customers',
        h2: 'Testimonials',
        heading: 'Read what our customers have to say about their experiences with Los Angeles Towing Services. We pride ourselves on providing exceptional service.',
        testimonials: [
            {
                avatarUrl: 'https://i.pravatar.cc/150?img=3',
                name: 'Jake R.',
                logo: undefined,
                title: 'Los Angeles, CA',
                quote:
                    '"I had an amazing experience with Los Angeles Towing Services when my car broke down on the 405. Their response time was incredibly fast, and the driver was both professional and friendly. They made a stressful situation much easier to handle. Highly recommend their services for anyone in need of reliable towing in LA."',
            },
            {
                avatarUrl: 'https://i.pravatar.cc/150?u=a042581f4e29026701b',
                name: 'Carlos G.',
                logo: undefined,
                title: 'Los Angeles, CA',
                quote:
                    '"Los Angeles Towing Services came to my rescue when I got a flat tire near downtown LA. Their technician was quick to arrive and very efficient in changing my tire. The pricing was transparent and fair. It’s great to know a dependable service like this exists in Los Angeles. Definitely keeping their number saved!"',
            },
            {
                avatarUrl: 'https://i.pravatar.cc/150?img=8',
                name: 'Samuel T.',
                logo: undefined,
                title: 'Los Angeles, CA',
                quote:
                    '"I needed a long-distance towing service to transport my vehicle from LA to San Francisco, and Los Angeles Towing Services handled everything perfectly. Their team was communicative, and the whole process was smooth and hassle-free. My car arrived safely and right on schedule. Exceptional service from start to finish!"',
            },
        ]
    },
    '404': {
        metaData: {
            title: '404 | Page Not Found - Los Angeles Towing Services | 24-Hour Towing | 323-922-4172',
            description: 'Oops! The page you\'re looking for couldn\'t be found. Los Angeles Towing Services offers professional towing and roadside assistance. Contact us for reliable support.',
        },
        h1: 'Oops, Looks Like You\'re Off Track!',
        subtitle: 'Sorry, we couldn\'t find the page you\'re looking for.',
        p: 'Need towing or roadside assistance? We\'re here for you 24/7. Contact Los Angeles Towing Services for reliable and efficient service.',
    }
}