import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const FlatbedTowing = () => {
    return (
        <Box>
            <Stack spacing={5} py={5}>
                <Heading as="h2" size="lg">Secure Flatbed Towing Services in Los Angeles, CA</Heading>
                <Text fontSize="md">
                    At Los Angeles Towing Services, we specialize in flatbed towing, providing a secure and damage-free
                    transportation solution for all types of vehicles. Whether you have a luxury car, an SUV, a
                    motorcycle, or a vehicle that requires special care, our flatbed towing services are the ideal
                    choice.
                </Text>
                <Text fontSize="md">
                    Our flatbed tow trucks are equipped with the latest technology and features to ensure your vehicle
                    is transported safely and efficiently across Los Angeles.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <Text><b>Safe Transportation:</b> Our flatbed tow trucks provide a secure platform for
                            transporting your vehicle, reducing the risk of wear and tear.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>Expert Handling:</b> Our drivers are trained in the proper loading, securing, and
                            unloading of vehicles to ensure your car arrives at its destination in the same condition it
                            was picked up.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>Adaptable for Various Vehicles:</b> Flatbed towing is suitable for transporting a wide
                            range of vehicles, including those with low ground clearance or specialty cars.</Text>
                    </ListItem>
                </UnorderedList>
                <Text fontSize="md">
                    Choose Los Angeles Towing Services for your flatbed towing needs. We guarantee a smooth, safe, and
                    reliable transportation experience.
                </Text>
                <Heading as="h3" size="md">Contact Us for Flatbed Towing in LA</Heading>
                <Text fontSize="md">
                    If you require flatbed towing services in Los Angeles, reach out to us for professional and
                    efficient service.
                </Text>
                <Text fontSize="md">
                    <Link color="teal.500" href="tel:3239224172">
                        Call 323-922-4172 for dependable flatbed towing solutions.
                    </Link>
                </Text>
                <Text fontSize="md">
                    We’re here to provide top-tier flatbed towing services, ensuring your vehicle's safety every step of
                    the way.
                </Text>
            </Stack>
        </Box>
    )
}