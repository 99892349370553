import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const RoadsideAssistance = () => {
    return (
        <Box>
            <Stack spacing={5} py={5}>
                <Heading as="h2" size="lg">Comprehensive Roadside Assistance in Los Angeles, CA</Heading>
                <Text fontSize="md">
                    Los Angeles Towing Services offers a full range of roadside assistance services to keep you moving
                    in LA's busy streets. Whether you're dealing with a flat tire, a dead battery, or you've run out of
                    fuel, our team is ready to assist you promptly and professionally.
                </Text>
                <Text fontSize="md">
                    Our roadside assistance services are designed to address common vehicle issues quickly and
                    efficiently, reducing your downtime and getting you back on the road as soon as possible.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <Text><b>24/7 Availability:</b> Our roadside assistance services are accessible any time of day,
                            ensuring help is always a phone call away.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>Quick Response:</b> We understand the importance of prompt service and strive to reach
                            you swiftly, minimizing your wait time.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>Professional Team:</b> Our technicians are experienced and equipped with the necessary
                            tools to provide effective roadside solutions.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>Wide Range of Services:</b> From <Link href={"/flat-tire-services"}>flat tire
                            services</Link> to battery jump-starts and fuel delivery, we cover all aspects of roadside
                            assistance.</Text>
                    </ListItem>
                </UnorderedList>
                <Text fontSize="md">
                    Rely on Los Angeles Towing Services for all your roadside assistance needs. Our goal is to provide
                    quick and reliable support to ensure your safety and convenience on the roads of Los Angeles.
                </Text>
                <Heading as="h3" size="md">Need Immediate Roadside Assistance?</Heading>
                <Text fontSize="md">
                    If you find yourself in need of roadside assistance in Los Angeles, don’t hesitate to reach out. Our
                    team is always prepared to offer efficient and courteous service.
                </Text>
                <Text fontSize="md">
                    <Link color="teal.500" href="tel:3239224172">
                        Call us at 323-922-4172 for fast and friendly roadside support.
                    </Link>
                </Text>
                <Text fontSize="md">
                    Contact Los Angeles Towing Services for dependable roadside assistance. We're committed to keeping
                    you safe and mobile in the City of Angels.
                </Text>
            </Stack>
        </Box>
    )
}