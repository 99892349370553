import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const CarLockoutService = () => {
    return (
        <Box>
            <Stack spacing={5} py={5}>
                <Heading as="h2" size="lg">Professional Car Lockout Services in Los Angeles, CA</Heading>
                <Text fontSize="md">
                    Locked out of your car in Los Angeles? It happens to the best of us, but worry not! Los Angeles
                    Towing Services offers prompt and efficient car lockout services. We understand the frustration and
                    urgency of being locked out, which is why we provide quick and non-invasive solutions to regain
                    access to your vehicle.
                </Text>
                <Text fontSize="md">
                    Our team is equipped with the right tools and skills to unlock your vehicle without causing any
                    damage.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <Text><b>Fast Response:</b> We strive to reach you as quickly as possible to resolve your car
                            lockout situation, minimizing your wait and stress.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>Damage-Free Techniques:</b> Our experienced technicians use safe and effective methods
                            to unlock your car without any damage to the lock or vehicle.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>24/7 Availability:</b> Car lockouts can happen at any time, which is why our lockout
                            services are available around the clock in Los Angeles.</Text>
                    </ListItem>
                </UnorderedList>
                <Text fontSize="md">
                    If you're locked out of your car in Los Angeles, don’t hesitate to call Los Angeles Towing Services
                    for swift and professional assistance.
                </Text>
                <Heading as="h3" size="md">Need Immediate Car Lockout Assistance?</Heading>
                <Text fontSize="md">
                    Reach out to us for quick and reliable car lockout services. We're here to help you regain access to
                    your car swiftly and safely.
                </Text>
                <Text fontSize="md">
                    <Link color="teal.500" href="tel:3239224172">
                        Call 323-922-4172 for immediate car lockout support.
                    </Link>
                </Text>
                <Text fontSize="md">
                    At Los Angeles Towing Services, we're dedicated to providing you with the best car lockout solutions
                    in the city.
                </Text>
            </Stack>
        </Box>
    )
}