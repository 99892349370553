import {createBrowserRouter} from 'react-router-dom';
import HomeLayout from '../Layouts/HomeLayout';
import About from '../Pages/About';
import ContactPage from '../Pages/ContactPage';
import Home01 from '../Pages/Homes/Home01';
import Services01 from '../Pages/Services/Services01';
import ErrorPage from '../Pages/ErrorPage/ErrorPage';
import Blog from "../Pages/Blog";
import CarLockoutService from "../Pages/Services/CarLockoutService";
import VehicleRecovery from "../Pages/Services/VehicleRecovery";
import FlatbedTowing from "../Pages/Services/FlatbedTowing";
import EmergencyTowing from "../Pages/Services/EmergencyTowing";
import RoadsideAssistance from "../Pages/Services/RoadsideAssistance";
import LongDistanceTowing from "../Pages/Services/LongDistanceTowing";
import FlatTire from "../Pages/Services/FlatTire";
import Careers from "../Pages/Careers";
import React from "react";
import {BlogPost} from "../Layouts/BlogPost";
import FuelDelivery from "../Pages/Services/FuelDelivery";
import JumpStartService from "../Pages/Services/JumpStartService";


export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: '/',
                element: <Home01/>
            },
            {
                path: '/blog',
                element: <Blog/>
            },
            {
                path: '/services',
                element: <Services01/>
            },
            {
                path: '/car-lockout-service',
                element: <CarLockoutService/>
            },
            {
                path: '/flatbed-towing',
                element: <FlatbedTowing/>
            },
            {
                path: '/emergency-towing',
                element: <EmergencyTowing/>
            },
            {
                path: '/long-distance-towing',
                element: <LongDistanceTowing/>
            },
            {
                path: '/roadside-assistance',
                element: <RoadsideAssistance/>
            },
            {
                path: '/vehicle-recovery',
                element: <VehicleRecovery/>
            },
            {
                path: '/flat-tire-services',
                element: <FlatTire/>
            },
            {
                path: '/fuel-delivery',
                element: <FuelDelivery/>
            },
            {
                path: '/jump-start-service',
                element: <JumpStartService/>
            },
            {
                path: '/about',
                element: <About/>
            },
            {
                path: '/careers',
                element: <Careers/>
            },
            {
                path: '/contact',
                element: <ContactPage/>
            },
            {
                path: '/blog/:id',
                element: <BlogPost/>
            },
            {
                path: '*',
                element: <ErrorPage/>
            },
        ]
    }
])

