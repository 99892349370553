import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const FlatTire = () => {
    return (
        <Box>
            <Stack spacing={5} py={5}>
                <Heading as="h2" size="lg">Responsive Flat Tire Services in Los Angeles, CA</Heading>
                <Text fontSize="md">
                    A flat tire can disrupt your journey, especially in a bustling city like Los Angeles. Los Angeles
                    Towing Services provides quick and reliable flat tire services to get you back on the road swiftly.
                    Whether you need a tire change, repair, or assistance with a spare tire, our team is equipped to
                    handle your needs efficiently.
                </Text>
                <Text fontSize="md">
                    Our service is designed to address flat tire emergencies promptly, ensuring minimal downtime and
                    inconvenience.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <Text><b>Fast and Efficient Service:</b> We prioritize quick response to flat tire situations,
                            aiming to resolve your issue as rapidly as possible.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>Experienced Technicians:</b> Our team is skilled in handling various types of tires and
                            vehicles, ensuring proper tire change or repair.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>24/7 Availability:</b> Flat tires can happen at any time, and our services are
                            available around the clock for your convenience.</Text>
                    </ListItem>
                    <ListItem>
                        <Text><b>Comprehensive Tire Solutions:</b> In addition to tire changes and repairs, we
                            offer <Link href={"/roadside-assistance"}>roadside assistance</Link> for related
                            needs.</Text>
                    </ListItem>
                </UnorderedList>
                <Text fontSize="md">
                    For immediate assistance with flat tires in Los Angeles, turn to Los Angeles Towing Services for
                    fast, reliable, and professional support.
                </Text>
                <Heading as="h3" size="md">Need Help with a Flat Tire?</Heading>
                <Text fontSize="md">
                    If you’re facing a flat tire in Los Angeles, don’t hesitate to contact us. Our team is ready to
                    provide you with efficient and hassle-free tire services.
                </Text>
                <Text fontSize="md">
                    <Link color="teal.500" href="tel:3239224172">
                        Call 323-922-4172 for prompt flat tire support.
                    </Link>
                </Text>
                <Text fontSize="md">
                    Rely on Los Angeles Towing Services for all your flat tire needs, ensuring a quick return to your
                    journey with safety and ease.
                </Text>
            </Stack>
        </Box>
    )
}