import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const JumpStart = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Prompt Fuel Delivery Services in Los Angeles, CA</Heading>
                <Text>
                    Caught with an empty tank in Los Angeles? No problem! Los Angeles Towing Services comes to your
                    rescue with efficient fuel delivery. Whether you're on the bustling city roads or parked at your
                    residence, our quick response ensures you're never left stranded. Our service is a lifesaver for
                    those moments when you're just a bit short of reaching the nearest gas station.
                </Text>
                <Text>
                    Our service is designed to provide you with fast and convenient fuel delivery, wherever you are in
                    Los Angeles.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Rapid Response:</b> We pride ourselves on quick response times, significantly reducing your
                        waiting period.
                    </ListItem>
                    <ListItem>
                        <b>Premium Fuel:</b> We deliver high-grade fuel suitable for a wide range of vehicles, ensuring
                        your drive is smooth and efficient.
                    </ListItem>
                    <ListItem>
                        <b>Expert Handling:</b> Our team is proficient in safe fuel delivery techniques, ensuring a
                        hassle-free experience for you.
                    </ListItem>
                    <ListItem>
                        <b>24-Hour Service:</b> Available around the clock, our fuel delivery services are just a call
                        away, anytime.
                    </ListItem>
                </UnorderedList>
                <Text>
                    Rely on Los Angeles Towing Services for quick and efficient fuel delivery in Los Angeles, CA. We're
                    here to ensure you're back on the road in no time.
                </Text>
                <Heading as={'h4'}>Why Choose Our Fuel Delivery?</Heading>
                <Text>
                    Our fuel delivery service is the perfect solution for unexpected fuel shortages, saving you time and
                    avoiding hassles. It's ideal for emergencies or when you're too far from a filling station.
                </Text>
                <Heading as={'h4'}>Extensive Roadside Assistance Offerings</Heading>
                <Text>
                    Beyond fuel delivery, we offer a comprehensive suite of roadside assistance services,
                    including <Link href={"/emergency-towing"}>emergency towing</Link>, <Link
                    href={"/jump-start-service"}>jump start services</Link>, <Link href={"/car-lockout-service"}>vehicle
                    lockout assistance</Link>, and more. Our goal is to be your go-to for all roadside emergencies.
                </Text>
                <Text>
                    For reliable fuel delivery and a full range of roadside assistance in Los Angeles, CA, turn to Los
                    Angeles Towing Services.
                </Text>
                <Text>
                    <Link color="teal.500" href={"tel:3239224172"}>
                        Call us at 323-922-4172 for immediate assistance.
                    </Link>
                </Text>
            </Stack>
        </Box>
    )
}